<script setup>
const route = useRoute()

const isActive = (path) => {
  if (Array.isArray(path)) {
    return path.some(singlePath => route.path.includes(singlePath))
  }
  return route.path === path
}
</script>

<template>
  <!-- Mobile Menu (Bottom of the screen) -->
  <div
    class="print-hidden md:hidden fixed bottom-0 left-0 right-0 p-4 mt-4 flex justify-around rounded-t-2xl text-indigo-950 shadow-inner bg-slate-100 z-50">
    <nuxt-link to="/client/dashboard" role="button" class="menu-item" :class="{ active: isActive('/client/dashboard') }">
      <fa :icon="['fas', 'grid-2']" class="text-2xl mb-1" />
      <span class="text-sm font-bold font-logo">Dashoard</span>
    </nuxt-link>
    <nuxt-link to="/client/policies" role="button" class="menu-item" :class="{ active: isActive('/client/policies') }">
      <fa :icon="['fas', 'shield-halved']" class="text-2xl mb-1" />
      <span class="text-sm font-bold font-logo">Policies</span>
    </nuxt-link>
    <nuxt-link to="/client/claims" role="button" class="menu-item"
      :class="{ active: isActive(['/client/claims', '/client/newclaim', '/client/claim']) }">
      <fa :icon="['fas', 'memo-pad']" class="text-2xl mb-1" />
      <span class="text-sm font-bold font-logo">Claims</span>
    </nuxt-link>
    <nuxt-link to="/client/help" role="button" class="menu-item" :class="{ active: isActive('/client/help') }">
      <fa :icon="['fas', 'circle-question']" class="text-2xl mb-1" />
      <span class="text-sm font-bold font-logo">Help</span>
    </nuxt-link>
    <!--
    <nuxt-link to="/client/settings" class="menu-item" :class="{ active: isActive('/client/settings') }">
      <fa :icon="['fas', 'gear']" class="text-2xl mb-1" />
      <span class="text-sm font-bold font-logo">Settings</span>
    </nuxt-link>
    -->
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  @apply flex flex-col align-middle items-center p-2 min-w-24;
}

.active {
  @apply bg-white rounded-xl shadow-lg;
}
</style>
